import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CtaFooter from '../components/cta-footer';
import HeaderWaves from '../components/header-waves';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import WarenkorbBild from '../images/Functions_Warenkorb.jpg';
import SucheBild from '../images/Functions_Suche.jpg';
import KreditkarteBild from '../images/Functions_Kreditkarte.jpg';
import GutscheineBild from '../images/Functions_Gutscheine-2.jpg';
import BenutzerkontenBild from '../images/Functions_Benutzerkonten.jpg';
import KontaktformularBild from '../images/Functions_Kontaktformular.jpg';
import UsermanagementBild from '../images/Functions_Usermanagement.jpg';
import MatomoAnalyticsBild from '../images/Functions_MatomoAnalytics.jpg';
import VirtuellerServerBild from '../images/Functions_VirtuellerServer.jpg';
import LogoSwissPost from '../images/Logo_SwissPost.jpg';

const kachelnContent = [
  {
    icon: 'la-shopping-cart',
    title: 'Warenkorb',
    intro: 'Gewohnter Onlineshop-Komfort.',
    img: WarenkorbBild,
    body:
      'Bieten Sie Ihren Besuchern und Kunden den gewohnten Komfort, mit einem modernen responsiven Warenkorb.',
  },
  {
    icon: 'la-search',
    title: 'Praktische Suchfunktion',
    intro: 'Produkte schneller Finden.',
    img: SucheBild,
    body:
      'Begleiten Sie Ihre Besuchern vom Suchen zum Finden, mit unserer schnellen und komfortablen Suchfunktion.',
  },
  {
    icon: 'la-credit-card',
    title: 'Kreditkartenzahlung',
    intro: 'Einkaufen, einfach & schnell.',
    img: KreditkarteBild,
    body:
      'Neben der Bezahloption «Rechnung» können Ihre Shopbesucher direkt mit Kreditkarte bezahlen, das entlastet Ihre Administration und erhöht die Attraktivität Ihres Shops.',
  },
  {
    icon: 'la-gifts',
    title: 'Gutscheinfunktion',
    intro: 'Schaffen Sie Einkaufsanreize.',
    img: GutscheineBild,
    body:
      'Erweitern Sie Ihre Kundschaft mit attraktiven Gutscheinen und eröffnen Sie sich neue Marketingoptionen.',
  },
  {
    icon: 'la-users',
    title: 'Benutzerkonten',
    intro: 'Erleichtern Sie das Einkaufserlebnis Ihrer Kunden.',
    img: BenutzerkontenBild,
    body:
      'Behalten Sie einen Überblick über Ihre Kundschaft und analysieren und optimieren Sie Ihren Shop nach Kundenbedürfnissen.',
  },
  {
    icon: 'la-address-book',
    title: 'Kontaktformular',
    intro: 'Schneller & einfacher Kontakt.',
    img: KontaktformularBild,
    body:
      'Erleichtern Sie den Kontakt zu Ihren Kunden mit einem praktischen & schnellen Kommunikationskanal.',
  },
  {
    icon: 'la-chart-pie',
    title: 'Matomo Analytics',
    intro: 'Analysieren Sie das Klickverhalten Ihrer Shopbesucher.',
    img: MatomoAnalyticsBild,
    body:
      'Wissen ist Umsatz! Überblicken und erkennen Sie die Besucherflüsse in Ihrem Shop und optimieren Sie so die Struktur und Produktpräsentation.',
  },
  {
    icon: 'la-server',
    title: 'Virtueller Server',
    intro: 'Sicheres & schnelles Hosting für Ihren Shop.',
    img: VirtuellerServerBild,
    body:
      'Genügend Speicherplatz und hindernisfreie Auslieferung des Shops an Ihre Besucher, ermöglicht durch ein Hosting in isolierter Serverumgebung mit verschlüsselter Datenübertragung per SSL/TLS.',
  },
  {
    icon: 'la-puzzle-piece',
    title: 'Erweiterungen',
    intro: 'Von Designänderungen bis Spezialfunktionen.',
    img: null,
    body:
      'Hinter dem Produkt «Sofortshop» steht die Berner Agentur «Novalab». Wir realisieren seit 2006 für unsere Kunden Webseiten, Webapplikationen und Onlineshops. Wir können Ihnen von der Konzeption über ein individuelles Design bis hin zur Programmierung von Spezialfunktionen alles anbieten.',
  },
];

class Kachel extends React.Component {
  state = {
    opened: false,
  };

  render() {
    const {
      props: { icon, title, intro, img, body },
      state: { opened },
    } = this;

    return (
      <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
        <div
          className={
            (opened ? 'kachel-open' : '') +
            ' flex-1 rounded-t rounded-b-none overflow-hidden shadow px-6 py-4'
          }
        >
          <div className="w-full font-bold text-xl text-gray-800 leading-none mb-2">
            <i className={'las ' + icon + ' text-3xl text-green-500 mr-3'}></i>
            {title}
          </div>
          <p className="text-gray-800 text-base mb-1">
            {intro}
            <a
              className={(opened ? 'hidden' : '') + ' text-blue-500 block md:hidden pt-3'}
              onClick={() => {
                this.setState({ opened: !opened });
              }}
            >
              mehr
            </a>
          </p>
          <div className={(opened ? '' : 'hidden') + ' md:block'}>
            {img && (
              <div className="flex justify-center my-4">
                <img
                  src={img}
                  className="border"
                  alt="Bild Warenkorbfunktion"
                />
              </div>
            )}
            <p className="text-gray-800 text-base">{body}</p>
            <a
              className={(opened ? '' : 'hidden') + ' text-blue-500 block md:hidden pt-3'}
              onClick={() => {
                this.setState({ opened: !opened });
              }}
            >
              weniger
            </a>
          </div>
        </div>
      </div>
    );
  }
}

function Funktionen() {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg)/" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Funktionsübersicht - In 48h zum eigenen Online-Shop "
        description="Alle Funktionen eines professionellen Shops. In kürzester Zeit als Standardversion aufgebaut, aber jederzeit erweiterbar."
      />

      <HeaderWaves />

      {/* Title cards */}
      <section className="bg-white pb-8 md:py-8">
        <div className="container mx-auto flex flex-wrap pt-4">
          <h1 className="w-full md:text-center text-gray-800 px-6 text-3xl md:text-4xl">
            Ultraschnell, professionell & zukunftssicher
          </h1>
          <p className="text-gray-800 text-base px-6 mb-5 mx-auto">
            Mit Sofortshop.ch entscheiden Sie sich für eine moderne Schweizer
            Shoplösung aus professioneller Hand, bereit für Sie innert 48h nach
            Bestellung.
          </p>

          <h2 className="w-full my-2 text-3xl font-bold leading-tight ml-6 md:text-center text-gray-800 mt-12">
            Unser «All-In-One»-Paket
          </h2>
          <p className="text-gray-800 text-base px-6 mb-5 mx-auto">
            Unser Komplettpaket «All-In-One» enthält alles nützliche und
            notwendige das Sie benötigen, um sofort loszulegen.
          </p>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          {kachelnContent.map((data, index) => {
            return <Kachel {...data} key={index} />;
          })}

          <h2 className="w-full my-2 text-3xl font-bold leading-tight ml-6 md:text-center text-gray-800 mt-12">
            Inklusive modernem & responsivem Design
          </h2>
          <p className="text-gray-800 text-base px-6 mb-5 mx-auto">
            Wir versorgen Sie mit einer modernen Shop-Oberfläche, die auch auf
            Mobilgeräten ausgezeichnet funktioniert. Sie wählen lediglich Ihre
            gewünschte Grundfarbe und versorgen uns mit Ihrem Logo, den Rest
            erledigen wir.
          </p>
          <div className="w-full md:w-3/4 my-8 px-6 mx-auto">
            <Slider {...settings}>
              {data.allFile.edges.map((image, index) => (
                <div key={index}>
                  <Img
                    fluid={image.node.childImageSharp.fluid}
                    alt="Beispiel Shop Design"
                  />
                </div>
              ))}
            </Slider>
          </div>

          <h2 className="w-full my-2 text-3xl font-bold leading-tight ml-6 text-left md:text-center text-gray-800 mt-12">
            Optimiert für den Schweizer Markt
          </h2>
          <p className="w-full text-gray-800 text-base px-6 mb-5 mx-auto text-left md:text-center">
            Weil wir's am besten kennen: Unser Shoppaket erhalten Sie
            vorkonfiguriert für den Schweizer Markt.
          </p>

          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <div className="w-full font-bold text-xl text-gray-800 px-6">
                Eingerichtet für die Schweizer Post
                <img
                  src={LogoSwissPost}
                  className="h-8"
                  alt="Logo der Schweizer Post"
                />
              </div>
              <p className="text-gray-800 text-base px-6 mb-5">
                Der Versand ist für die Schweizer Post konfiguriert. Ihr Kunde
                kann zwischen einem <i>Economy</i>- und <i>Priority</i>-Versand
                wählen. Die Berechnung erfolgt aufgrund des Gewichts.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <div className="w-full font-bold text-xl text-gray-800 px-6">
                Support in Ihrer Nähe
              </div>
              <p className="text-gray-800 text-base px-6 mb-5">
                Mit unserem Support, zusätzlichem Handbuch sowie zahlreichen
                Möglichkeiten zur Erweiterung sind Sie optimal aufgestellt.
              </p>
            </div>
          </div>
        </div>

        <div className="hidden w-full p-6 flex flex-col flex-shrink">
          <a
            href="/kontakt"
            className="hidden md:inline ml-auto hover:shadow-md bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
          >
            <span className="rounded w-4 bg-orange-500 font-bold text-white px-1">
              PDF
            </span>{' '}
            Informationen als PDF herunterladen
          </a>
          <a
            href="/kontakt"
            className="inline md:hidden mx-auto hover:shadow-md bg-white text-gray-800 font-bold rounded-full py-4 px-8 shadow-lg"
          >
            <span className="rounded w-4 bg-orange-500 font-bold text-white px-1">
              PDF
            </span>{' '}
            Als PDF speichern
          </a>
        </div>
      </section>

      <CtaFooter />
    </Layout>
  );
}

export default Funktionen;
